<template>
    <router-view></router-view>
</template>

<script>
export default {
  name: 'PageContainer',
  components: {
  },
}
</script>
<style lang="scss" >
.theme--light.v-application {  
    background: #f4f5fa;
}

.form-btn {
   width: 100px;
}

.report-btn {
   width: 220px;
}
</style>
